import React, { FC, useState, useEffect, useMemo } from 'react'
import {
    DataSheetGrid,
    checkboxColumn,
    textColumn,
    keyColumn,
} from 'react-datasheet-grid'
import 'react-datasheet-grid/dist/style.css'

import { api } from '../api.service';
const API_URL = process.env.REACT_APP_API_URL
const Data = () => {

    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [key, setKey] = useState(0)
    const [selectedDataModel, setSelectedDataModel] = useState(null)
    const [hideFields, setHideFields] = useState(false)

    const createdRowIds = useMemo(() => new Set(), [])
    const deletedRowIds = useMemo(() => new Set(), [])
    const updatedRowIds = useMemo(() => new Set(), [])

    useEffect(() => {
        api(`${API_URL}/datamodel`).then(x => {
            let _data = []
            for (let s of x) {
                _data.push({ ...s });
            }
            setKey(Math.random())
            setData(_data);
            if (_data[0])
                setSelectedDataModel(_data[0])
        });
    }, [])

    useEffect(() => {
        if (!selectedDataModel) return;

        api(`${API_URL}/data/objects/${selectedDataModel.systemName}`).then(x => {
            let _data = []
            for (let s of x) {
                _data.push({ _id: s._id, ...s.fields });
            }
            setTableData(_data);
        });
        setHideFields(true);
        setTimeout(() => setHideFields(false), 100);
    }, [selectedDataModel])

    const getColumns = () => {
        return selectedDataModel ? [{ ...keyColumn('_id', textColumn), title: 'Id', disabled: true }].concat(selectedDataModel.fields.map((x) => {
            return {
                ...keyColumn(x.systemName, textColumn),
                title: x.display,
            }
            return {}
        }).filter((x) => x)) : [];
    }

    const onSave = () => {
        const postData = [];
        for (let row of tableData) {
            if (deletedRowIds.has(row._id)) {
                row.delete = true;
                postData.push(row);
            }
            if (createdRowIds.has(row._id) || updatedRowIds.has(row._id)) {
                postData.push(row);
            }
        }
        createdRowIds.clear()
        deletedRowIds.clear()
        updatedRowIds.clear()
        api(`${API_URL}/data/objects/${selectedDataModel.systemName}`, { objects: postData }).then(x => {
            let _data = []
            for (let s of x) {
                _data.push({ _id: s._id, ...s.fields });
            }
            console.log(_data)
            setTableData(_data);
        });
    }

    return (
        <>
            <div class="sm:flex-auto">
                <h1 class="text-xl font-semibold text-gray-900">Data</h1>
                <p class="mt-2 text-sm text-gray-700">View, export and update your MVP data here. Changes will appear on your product immediately.</p>
            </div>
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">

                    {data.map(dataModel => {
                        return <a onClick={() => setSelectedDataModel(dataModel)}
                            className={selectedDataModel?._id != dataModel._id ? 'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                :
                                'border-indigo-500 text-indigo-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'}
                        > {dataModel.displayName} ({dataModel.systemName})</a>

                    })}
                </nav>
            </div>
            {selectedDataModel && <><div>
                <div className="mx-auto max-w-7xl">
                    <div className="mt-2 flex flex-col">
                        <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                            <div className="bg-white p-5">
                                <button disabled={!(!!createdRowIds.size || !!deletedRowIds.size || !!updatedRowIds.size)} type='button' onClick={onSave} className='inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'>
                                    Save
                                </button>
                                <br /><br />
                                <h1>{selectedDataModel.displayName}</h1>
                                {!hideFields && <DataSheetGrid key={key}
                                    value={tableData}
                                    columns={getColumns()}
                                    rowClassName={({ rowData }) => {
                                        if (deletedRowIds.has(rowData._id)) {
                                            return 'row-deleted'
                                        }
                                        if (createdRowIds.has(rowData._id)) {
                                            return 'row-created'
                                        }
                                        if (updatedRowIds.has(rowData._id)) {
                                            return 'row-updated'
                                        }
                                    }}
                                    onChange={(newValue, operations) => {
                                        for (const operation of operations) {
                                            console.log(operation)
                                            if (operation.type === 'CREATE') {
                                                newValue
                                                    .slice(operation.fromRowIndex, operation.toRowIndex)
                                                    .forEach(({ _id }) => createdRowIds.add(_id))
                                            }

                                            if (operation.type === 'UPDATE') {
                                                newValue
                                                    .slice(operation.fromRowIndex, operation.toRowIndex)
                                                    .forEach(({ _id }) => {
                                                        if (!createdRowIds.has(_id) && !deletedRowIds.has(_id)) {
                                                            updatedRowIds.add(_id)
                                                        }
                                                    })
                                            }

                                            if (operation.type === 'DELETE') {
                                                let keptRows = 0

                                                data
                                                    .slice(operation.fromRowIndex, operation.toRowIndex)
                                                    .forEach(({ _id }, i) => {
                                                        updatedRowIds.delete(_id)

                                                        if (createdRowIds.has(_id)) {
                                                            createdRowIds.delete(_id)
                                                        } else {
                                                            deletedRowIds.add(_id)
                                                            newValue.splice(
                                                                operation.fromRowIndex + keptRows++,
                                                                0,
                                                                data[operation.fromRowIndex + i]
                                                            )
                                                        }
                                                    })
                                            }
                                        }

                                        setTableData(newValue)
                                    }}
                                />}

                            </div>

                        </div></div></div></div>









            </>}

        </>

    )
}

export default Data;