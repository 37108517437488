import React, { Fragment, useState, useEffect } from 'react'
import {
    DataSheetGrid,
    checkboxColumn,
    textColumn,
    keyColumn,
} from 'react-datasheet-grid'
import 'react-datasheet-grid/dist/style.css'
import { api, api_delete } from '../api.service'
import { Dialog, Transition } from '@headlessui/react'
import Confirm from '../components/confirm'

const API_URL = process.env.REACT_APP_API_URL
const Users = () => {

    const [tableData, setTableData] = useState([])

    const [deleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => {
        api(`${API_URL}/admin/users`).then(x => {
            setTableData(x.users);
        });
    }, [])

    const deleteUser = (id) => {
        api_delete(`${API_URL}/admin/user/${id}`).then(x => {
            api(`${API_URL}/admin/users`).then(x => {
                setTableData(x.users);
            });
            setDeleteOpen(false);
        });
    }

    return (
        <>
            <Confirm open={!!deleteOpen} cancel={() => setDeleteOpen(false)} confirm={() => deleteUser(deleteOpen._id)}></Confirm>

            <div className="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-xl font-semibold text-gray-900">Users</h1>
                    <p class="mt-2 text-sm text-gray-700">View and manage your system users.</p>
                </div>
            </div>

            <div class="mt-8 flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">

                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    {tableData.map(x => <tr>
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{x.name}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{x.email}</td>
                                        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <a onClick={() => setDeleteOpen(x)} class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Delete</a>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Users