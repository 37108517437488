import React, { FC, useState, useEffect } from 'react'
import {
    DataSheetGrid,
    checkboxColumn,
    textColumn,
    keyColumn,
} from 'react-datasheet-grid'
import 'react-datasheet-grid/dist/style.css'
import { api } from '../api.service';

const API_URL = process.env.REACT_APP_API_URL
const Schema = () => {

    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [selectedDataModel, setSelectedDataModel] = useState(null)

    const [currentName, setCurrentName] = useState("")
    const [currentDisplay, setCurrentDisplay] = useState("")
    const [addingNew, setAddingNew] = useState(false)


    useEffect(() => {
        api(`${API_URL}/datamodel`).then(x => {
            let _data = []
            for (let s of x) {
                _data.push({ ...s });
            }
            setData(_data);
            if (_data[0])
                setSelectedDataModel(_data[0])
        });
    }, [])

    useEffect(() => {
        if (!selectedDataModel) return;
        setTableData(selectedDataModel?.fields);
        setCurrentName(selectedDataModel.systemName)
        setCurrentDisplay(selectedDataModel.displayName)
    }, [selectedDataModel])

    const columns = ([
        {
            ...keyColumn('systemName', textColumn),
            title: 'System Name',
        },
        {
            ...keyColumn('display', textColumn),
            title: 'Display Name',
        },
        {
            ...keyColumn('dataType', textColumn),
            title: 'Data Type',
        },
        {
            ...keyColumn('relationalObjectType', textColumn),
            title: 'Relational Data Object',
        },
        {
            ...keyColumn('relationalObjectList', checkboxColumn),
            title: 'Relational Data Is List',
        },
    ])

    const onSave = () => {
        api(`${API_URL}/datamodel`, { object: { _id: selectedDataModel._id, systemName: currentName, displayName: currentDisplay, fields: tableData } }).then(x => {
            let _data = []
            for (let s of x) {
                _data.push({ ...s });
            }
            setData(_data);
        });
    }

    const onAddNew = () => {
        api(`${API_URL}/datamodel`, { object: { _id: selectedDataModel._id, systemName: currentName, displayName: currentDisplay, fields: tableData } }).then(x => {
            api(`${API_URL}/datamodel`).then(x => {
                let _data = []
                for (let s of x) {
                    _data.push({ ...s });
                }

                setAddingNew(false)
                setData(_data);
                if (_data[0])
                    setSelectedDataModel(_data[_data.length - 1])
            });
        });
    }

    return (
        <>

            <div class="sm:flex-auto">
                <h1 class="text-xl font-semibold text-gray-900">Data Schema</h1>
                <p class="mt-2 text-sm text-gray-700">These are your Verticode Data Models, they define the data you're collecting and it's structure. You can make changes here if you need to update the data you're collecting.</p>
            </div>
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">

                    {data.map(dataModel => {
                        return <a onClick={() => { setAddingNew(false); setSelectedDataModel(dataModel) }}
                            className={selectedDataModel?._id != dataModel._id ? 'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                :
                                'border-indigo-500 text-indigo-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'}
                        > {dataModel.displayName} ({dataModel.systemName})</a>

                    })}

                    <a onClick={() => { setAddingNew(true); setSelectedDataModel({ systemName: "new", displayName: "New", fields: [] }) }}
                        className={!addingNew ? 'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            :
                            'border-indigo-500 text-indigo-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'}
                    >Add New</a>
                </nav>
            </div>


            {selectedDataModel && <div className="mx-auto max-w-7xl">
                <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                        <div className="bg-white p-5">
                            {!addingNew && <button type='button' onClick={onSave} className='inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'>
                                Save
                            </button>}
                            {addingNew && <button type='button' onClick={onAddNew} className='inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'>
                                Add
                            </button>}

                            <div class="grid grid-cols-2 gap-4 mt-4">
                                <div>
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                        System Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            value={currentName}
                                            onChange={(e) => setCurrentName(e.target.value)}
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="display" className="block text-sm font-medium text-gray-700">
                                        Display Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            value={currentDisplay}
                                            onChange={(e) => setCurrentDisplay(e.target.value)}
                                            type="text"
                                            name="display"
                                            id="display"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>

                            <label className="block text-sm font-medium text-gray-700 mt-5">
                                Fields
                            </label>
                            <DataSheetGrid
                                value={tableData}
                                onChange={x => setTableData(x)}
                                columns={columns}
                            />

                        </div></div></div></div>}

        </>

    )
}

export default Schema